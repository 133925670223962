exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parceiros-tsx": () => import("./../../../src/pages/parceiros.tsx" /* webpackChunkName: "component---src-pages-parceiros-tsx" */),
  "component---src-pages-produtores-tsx": () => import("./../../../src/pages/produtores.tsx" /* webpackChunkName: "component---src-pages-produtores-tsx" */),
  "component---src-pages-revendas-tsx": () => import("./../../../src/pages/revendas.tsx" /* webpackChunkName: "component---src-pages-revendas-tsx" */),
  "component---src-pages-seguros-[name]-tsx": () => import("./../../../src/pages/seguros/[name].tsx" /* webpackChunkName: "component---src-pages-seguros-[name]-tsx" */),
  "component---src-pages-seguros-product-name-tsx": () => import("./../../../src/pages/seguros/{Product.name}.tsx" /* webpackChunkName: "component---src-pages-seguros-product-name-tsx" */)
}

